import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    useMediaQuery,
    Icon,
    Portal,
} from '@chakra-ui/react';
import { type ComponentProps, type ReactNode } from 'react';

import { useAppContext } from '../../../../modules/core/contexts/AppContext';
import { ReactComponent as IconHelpSvg } from './icon-help.svg';

export type HelpPopoverProps = { header: ReactNode } & ComponentProps<any>;

export const HelpPopover = ({ header, children }: HelpPopoverProps) => {
    const [isTouch] = useMediaQuery('(pointer: coarse)');
    const { containerRef } = useAppContext();

    return (
        <Popover variant="dialog" trigger={isTouch ? 'click' : 'hover'}>
            <PopoverTrigger>
                <Icon as={IconHelpSvg} w={4} h={4} ml={1} cursor="pointer" verticalAlign="middle" />
            </PopoverTrigger>
            <Portal containerRef={containerRef}>
                <PopoverContent whiteSpace="normal">
                    <PopoverHeader>{header}</PopoverHeader>
                    <PopoverBody>{children}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
