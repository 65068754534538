import { type SubmitHandler, useForm, type UseFormProps } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect } from 'react';

import {
    type BillingInputs,
    type BillingResult,
    useBilling,
    BillingUserClass,
} from '../../../../modules/api/hooks/useBilling';
import { useDataLayer } from '../../../../modules/core/hooks/useDataLayer';
import { useCalculatorContext } from '../../contexts/CalculatorContext';

export const useCalculatorForm = (options?: UseFormProps<BillingInputs>) => {
    const {
        setCalculatorState,
        currency,
        paymentDetails,
        autoStart,
        disallowEmail,
        disableAnalytics,
    } = useCalculatorContext();
    const form = useForm<BillingInputs>({
        mode: 'onChange',
        defaultValues: {
            ...paymentDetails,
            usemeClub: paymentDetails.contractorUserClass === BillingUserClass.loyal,
            disallowEmail,
        },
        ...options,
    });
    const { sendToGTM } = useDataLayer();

    const onSuccess = (result: BillingResult) => {
        setCalculatorState(result);
    };

    const onError = async (error: any) => {
        const errorResponse: ErrorResponse | undefined = await error?.response?.json?.();
        if (errorResponse?.errors !== undefined) {
            form.setError('amount', {
                type: 'custom',
                message: Object.values(errorResponse.errors)[0],
            });
            setCalculatorState({
                payin: [],
                payout: [],
                priceComponents: [],
                currency,
            });
        }
    };

    const { mutateAsync } = useBilling({ onSuccess, onError });

    const onAutoSubmit: SubmitHandler<BillingInputs> = async () => {
        return await mutateAsync(form.getValues());
    };

    const onSubmit: SubmitHandler<BillingInputs> = async (inputs) => {
        if (!disableAnalytics) {
            sendToGTM({
                event: 'calculator-submit',
            });
        }
        return await mutateAsync(inputs);
    };

    const onChange = useDebouncedCallback(onSubmit, 150);

    useEffect(() => {
        if (autoStart) {
            void form.handleSubmit(onAutoSubmit)();
        }
    }, [autoStart]);

    return {
        form,
        onChange,
        onSubmit,
    };
};
