import {
    Flex,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    chakra,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SelectCurrencyField } from '../../../../modules/core/components/SelectCurrency';
import { useCalculatorType } from '../../hooks/useCalculatorType';
import { useResult } from '../../hooks/useResult';
import { FieldVatInfo } from '../FieldVatInfo';

export const ResultField = () => {
    const { t } = useTranslation();
    const { isGrossToNetto } = useCalculatorType();
    const { result, isVat } = useResult();

    return (
        <InputGroup>
            <FormControl
                variant="fast"
                h="8.1rem"
                isReadOnly
                borderWidth="1px"
                borderColor="general.skyBlue"
            >
                <FormLabel>
                    {isGrossToNetto
                        ? t('calculator.payout')
                        : `${t('calculator.invoice')} (${t('calculator.contract').toLowerCase()})`}
                </FormLabel>

                <chakra.div
                    fontSize={
                        result !== null && isVat && result.toString().length > 6 ? '2rem' : '2.4rem'
                    }
                    fontWeight="bold"
                    pt={8}
                    px={5}
                >
                    {result}
                </chakra.div>

                {result !== null && isVat && result.toString().length < 10 && (
                    <FieldVatInfo value={result} />
                )}
            </FormControl>

            <InputRightElement w="auto" h="100%" mt={1}>
                <Flex alignContent="center" pointerEvents="none">
                    <SelectCurrencyField isReadOnly />
                </Flex>
            </InputRightElement>
        </InputGroup>
    );
};
