import {
    type FieldVatInfoProps,
    TextFieldVatInfo,
} from '../../../../modules/calculator-core/components/FieldVatInfo';

export const FieldVatInfo = ({ value, ...props }: FieldVatInfoProps) => {
    if (value === null) return <></>;

    return (
        <TextFieldVatInfo
            ml={`${
                value.toString().length > 5
                    ? value.toString().length * 8.5
                    : value.toString().length * 12
            }px`}
            top="-8px"
            {...props}
        >
            +VAT
        </TextFieldVatInfo>
    );
};
