import { type ElementType, StrictMode } from 'react';
import { createRoot, type Root } from 'react-dom/client';
import createCache, { type EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Container, Skeleton, Stack } from '@chakra-ui/react';

import { App } from '../../../../modules/app/components/App';

function FallbackComponent() {
    return (
        <Container maxW="container.xl">
            <Stack gap={3} py={4}>
                <Skeleton height="1.6rem" />
                <Skeleton height="1.6rem" />
                <Skeleton height="1.6rem" />
            </Stack>
        </Container>
    );
}

export function registerComponent(name: string, Component: ElementType, props?: string[]) {
    class Web2ReactComponent extends HTMLElement {
        root: Root;
        cache: EmotionCache;

        static get observedAttributes() {
            return props ?? [];
        }

        constructor() {
            super();
            const mountPoint = document.createElement('div');
            this.attachShadow({ mode: 'open' });
            this.shadowRoot?.appendChild(mountPoint);
            mountPoint.style.fontSize = '1.6rem';
            mountPoint.style.position = 'relative';
            mountPoint.style.zIndex = '1';

            this.cache = createCache({
                container: mountPoint,
                key: 'shadow-style',
            });

            this.root = createRoot(mountPoint);
            this.renderReact();
        }

        getAttributes() {
            const attributeNames = this.getAttributeNames();
            const attributes: Record<string, any> = {};
            for (const name of attributeNames) attributes[name] = this.getAttribute(name);
            return attributes;
        }

        renderReact() {
            const attributes = this.getAttributes();
            this.root.render(
                <StrictMode>
                    <CacheProvider value={this.cache}>
                        <App>
                            <ErrorBoundary fallback={<FallbackComponent />}>
                                <Component {...attributes}>
                                    <slot />
                                </Component>
                            </ErrorBoundary>
                        </App>
                    </CacheProvider>
                </StrictMode>,
            );
        }

        attributeChangedCallback(_attrName: any, _oldVal: any, _newVal: any) {
            this.renderReact();
        }
    }

    window.customElements.define(`react-${name}`, Web2ReactComponent);
}
