import {
    Badge,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Text,
    Link,
    chakra,
    Icon,
    type UseRadioGroupProps,
} from '@chakra-ui/react';
import { type ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';

import { useGlobalContext } from '../../../../modules/core/contexts/GlobalContext';
import { BillingCopyrightTransfer } from '../../../../modules/api/hooks/useBilling';
import { useCalculatorLanguage } from '../../../../modules/calculator-core/hooks/useCalculatorLanguage';
import { ReactComponent as IconHelpMenu } from './icon-help-menu.svg';

export type CopyrightTransferRadioProps = {
    onChange?: UseRadioGroupProps['onChange'];
} & ComponentProps<any>;

const RadioLabelCSS: CSSObject = {
    '&::first-letter': {
        textTransform: 'uppercase',
    },
};

export const CopyrightTransferRadio = ({
    onChange: onValueChange,
}: CopyrightTransferRadioProps) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const { currentLanguage } = useGlobalContext();
    const { language } = useCalculatorLanguage();
    const isPL = language === 'pl';

    return (
        <chakra.div mb={2.5}>
            <Controller
                name="copyrightTransfer"
                rules={{ required: t('common.errors.required') }}
                control={control}
                render={({
                    field: { ref, name, value, onChange, onBlur },
                    fieldState: { error },
                }) => (
                    <FormControl isInvalid={error !== undefined} mb={5}>
                        <FormLabel>
                            <Badge
                                borderRadius="xs"
                                fontSize="xs"
                                px={1}
                                py={0}
                                mr={3}
                                bg="general.orange"
                            >
                                {t('common.attention')}
                            </Badge>
                            <chakra.span>
                                {t('calculator_secpay.copyright_transfer.header')}
                            </chakra.span>{' '}
                        </FormLabel>

                        <RadioGroup
                            ref={ref}
                            name={name}
                            value={value}
                            onChange={(value) => {
                                void onChange(value);
                                if (typeof onValueChange === 'function') onValueChange(value);
                            }}
                            onBlur={onBlur}
                        >
                            <Stack spacing={6}>
                                <Radio value={BillingCopyrightTransfer.license}>
                                    <Text fontWeight="normal" css={RadioLabelCSS}>
                                        {t('calculator.copyright_transfer.license')}
                                    </Text>
                                    <Text fontSize="sm" fontWeight="normal">
                                        {isPL
                                            ? t(
                                                  'calculator.copyright_transfer.license.pl_description',
                                              )
                                            : t(
                                                  'calculator.copyright_transfer.license.en_description',
                                              )}
                                    </Text>
                                </Radio>
                                <Radio value={BillingCopyrightTransfer.protocol}>
                                    <Text fontWeight="normal" css={RadioLabelCSS}>
                                        {isPL
                                            ? t('calculator.copyright_transfer.protocol')
                                            : t(
                                                  'calculator.copyright_transfer.full_ownership_transfer',
                                              )}
                                    </Text>
                                    <Text fontSize="sm" fontWeight="normal">
                                        {isPL
                                            ? t(
                                                  'calculator.copyright_transfer.protocol.pl_description',
                                              )
                                            : t(
                                                  'calculator.copyright_transfer.protocol.en_description',
                                              )}
                                    </Text>
                                </Radio>
                                <Radio value={BillingCopyrightTransfer.without}>
                                    <Text fontWeight="normal" css={RadioLabelCSS}>
                                        {t('calculator.copyright_transfer.no_copyright_transfer')}
                                    </Text>
                                    <Text fontSize="sm" fontWeight="normal">
                                        {isPL
                                            ? t(
                                                  'calculator.copyright_transfer.no_copyright_transfer.pl_description',
                                              )
                                            : t(
                                                  'calculator.copyright_transfer.no_copyright_transfer.en_description',
                                              )}
                                    </Text>
                                </Radio>
                            </Stack>
                        </RadioGroup>
                        <FormErrorMessage whiteSpace="nowrap">{error?.message}</FormErrorMessage>
                    </FormControl>
                )}
            />

            <Link
                fontSize="sm"
                href={`https://help.useme.eu/hc/${
                    currentLanguage === 'pl' ? 'pl' : 'en-us'
                }/articles/360014412831`}
            >
                <Icon as={IconHelpMenu} stroke="currentColor" w="13px" h="13px" mr={1} />
                {t('calculator.copyright_transfer.link')}
            </Link>
        </chakra.div>
    );
};
