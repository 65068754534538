import { Heading, Flex, Stack, Grid, Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { MaxCommissionBanner } from '../../../../modules/calculator-core/components/MaxCommissionBanner';
import { useCalculatorType } from '../../../../modules/calculator-core/hooks/useCalculatorType';
import { inputWidth } from '../../../../modules/calculator-core/constants';
import { breakpoint } from '../../../../modules/design/utils/breakpoint';
import { PriceAndCurrencyField } from '../PriceAndCurrencyField';
import { ResultField } from '../ResultField';
import { SwitchBillingType } from '../SwitchBillingType';
import { CheckboxCopyrightTransferField } from '../CheckboxCopyrightTransferField';
import { CalculationDetails } from '../CalculationDetails';

export const CalculatorFormLayout = () => {
    const { isNettoToGross, isGrossToNetto } = useCalculatorType();
    const { t } = useTranslation();

    return (
        <CalcBox>
            <Grid
                templateColumns={{
                    base: '1fr',
                    md: `minmax(${inputWidth}, 1fr) auto minmax(${inputWidth}, 1fr)`,
                }}
                columnGap="1.6rem"
            >
                <Stack spacing={4}>
                    {isNettoToGross ? <FreelancerHeader /> : <EmployerHeader />}

                    <PriceAndCurrencyField />

                    {isNettoToGross && <CheckboxCopyrightTransferField />}
                </Stack>

                <Flex
                    alignItems="flex-start"
                    justifyItems="center"
                    mt={{ base: 4, md: 20 }}
                    mb={{ base: 4, md: 0 }}
                >
                    <SwitchBillingType />
                </Flex>

                <Stack spacing={4}>
                    {isGrossToNetto ? <FreelancerHeader /> : <EmployerHeader />}

                    <ResultField />

                    {isGrossToNetto && <CheckboxCopyrightTransferField />}
                </Stack>
            </Grid>

            <MaxCommissionBanner />

            <CalculationDetails mt={8} />

            <Text
                textStyle="small"
                color="general.paragraph"
                mt={8}
                mb={{ base: 10, md: 0 }}
                textAlign={{ base: 'center', md: 'left' }}
            >
                {t('calculator_landing.info_note')}
            </Text>
        </CalcBox>
    );
};

const FreelancerHeader = () => {
    const { t } = useTranslation();

    return <CalcHeading as="h3">{t('calculator.freelancer_header')}</CalcHeading>;
};

const EmployerHeader = () => {
    const { t } = useTranslation();

    return <CalcHeading as="h3">{t('calculator.employer_header')}</CalcHeading>;
};

const CalcHeading = styled(Heading)`
    color: var(--ck-colors-general-heading);
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 130% !important;
    text-align: center;

    ${breakpoint('md')} {
        text-align: left;
    }
`;

const CalcBox = styled(Box)`
    ${breakpoint('md')} {
        border-radius: var(--ck-radii-xl);
        background: var(--ck-colors-general-brightGray);
        border: 1px solid var(--ck-colors-general-sectionBorder);
        padding: var(--ck-space-12);
    }
`;
