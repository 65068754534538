import { FormProvider } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GoogleReCaptchaProvider } from '../../../../modules/core/hooks/useReCaptcha';
import { ssrContext } from '../../../../modules/core/contexts/GlobalContext';
import { useHandleFormErrors } from '../../../../modules/core/hooks/useHandleFormErrors';
import {
    SettleFreelancerContactPayload,
    settleFreelancerContact,
} from '../../../../modules/api/services/settle-freelancer-contact';
import { LetsTalkForm } from '../../components/LetsTalkForm';
import { useLetsTalkForm } from '../../components/LetsTalkForm/LetsTalkForm';

export default function LetsTalk() {
    const { t } = useTranslation();
    const toast = useToast();
    const form = useLetsTalkForm();
    const handleErrors = useHandleFormErrors(form, {
        first_name: 'firstName',
        last_name: 'lastName',
    });

    async function handleSubmit(data: SettleFreelancerContactPayload) {
        const result = await settleFreelancerContact(data);
        if (result?.errors !== undefined) {
            toast({
                title: t('contact.form.message_error'),
                status: 'error',
                isClosable: true,
            });
            return handleErrors(result.errors);
        }

        window.dataLayer = Array.isArray(window.dataLayer) ? window.dataLayer : [];
        window.dataLayer.push({
            event: 'form_contact_submit',
        });

        toast({
            title: t('contact.form.message_success'),
            status: 'success',
            isClosable: true,
        });
        form.reset();
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={ssrContext.recaptchaKey}
            scriptProps={{ async: true }}
        >
            <FormProvider {...form}>
                <LetsTalkForm onSubmit={handleSubmit} />
            </FormProvider>
        </GoogleReCaptchaProvider>
    );
}
