import { useMemo, type ComponentProps } from 'react';
import { Text, Image, Box, Link } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useGlobalContext } from '../../../../modules/core/contexts/GlobalContext';
import { LangCurrency } from '../../../../modules/core/components/LangCurrency';
import { useCalculatorContext } from '../../contexts/CalculatorContext';
import status from './status.svg';

export const MaxCommissionBanner = () => {
    const { watch } = useFormContext();
    const usemeClub: boolean = watch('usemeClub');
    const { currentLanguage } = useGlobalContext();
    const {
        paymentDetails: { isAchievedCommissionCap },
    } = useCalculatorContext();
    const isCommissionZero = isAchievedCommissionCap !== undefined && isAchievedCommissionCap;

    const HelpLink = useMemo(
        () => (
            <Link
                color="currentcolor"
                fontWeight="normal"
                textDecoration="underline"
                href={
                    currentLanguage === 'pl'
                        ? 'https://help.useme.eu/hc/pl/articles/360017653831-Jakie-są-koszty-wystawienia-faktury-przez-Useme'
                        : 'https://help.useme.eu/hc/en-us/articles/360017653831-How-much-does-it-cost-to-sign-a-contract-through-Useme'
                }
                target="_blank"
            />
        ),
        [currentLanguage],
    );

    return (
        <CommissionBanner>
            {!isCommissionZero && (
                <Text textStyle="body" fontWeight="bold" color="general.heading">
                    <Trans i18nKey="calculator.max_commission_banner.title" />
                </Text>
            )}
            <Text textStyle="small" color="general.darkBlue">
                {isCommissionZero ? (
                    <Trans i18nKey="calculator.zero_commission_banner.content" />
                ) : (
                    <>
                        {usemeClub ? (
                            <Trans
                                i18nKey="calculator.max_commission_banner.useme-club-memeber-content"
                                components={{ span: HelpLink, currency: <LangCurrency /> }}
                            />
                        ) : (
                            <Trans
                                i18nKey="calculator.max_commission_banner.content"
                                components={{ span: HelpLink, currency: <LangCurrency /> }}
                            />
                        )}
                    </>
                )}
            </Text>
        </CommissionBanner>
    );
};

export const CommissionBanner = ({ children }: ComponentProps<any>) => {
    return (
        <Banner>
            <Image src={status} alt="" />
            <Box>{children}</Box>
        </Banner>
    );
};

const Banner = styled(Box)`
    display: flex;
    gap: var(--ck-space-5);
    background: rgba(242, 201, 76, 0.1);
    border-radius: var(--ck-space-2);
    padding: var(--ck-space-4) var(--ck-space-6);
    margin: var(--ck-space-8) 0;
    align-items: center;
`;
