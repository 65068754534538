import { Checkbox, type CheckboxProps } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { BillingCopyrightTransfer } from '../../../../modules/api/hooks/useBilling';

export const CheckboxCopyrightTransfer = ({ onChange, children, ...props }: CheckboxProps) => {
    const { control } = useFormContext();
    const { field } = useController({
        name: 'copyrightTransfer',
        control,
    });

    return (
        <Checkbox
            {...props}
            ref={field.ref}
            name={field.name}
            value={field.value}
            defaultChecked={field.value === BillingCopyrightTransfer.license}
            onBlur={field.onBlur}
            onChange={(e) => {
                field.onChange(
                    e.target.checked
                        ? BillingCopyrightTransfer.license
                        : BillingCopyrightTransfer.without,
                );
                if (typeof onChange === 'function') onChange(e);
            }}
        >
            {children}
        </Checkbox>
    );
};
