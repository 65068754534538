import { useDataLayer } from '../../../../modules/core/hooks/useDataLayer';

export const useSendCategoryGTM = () => {
    const { sendToGTM } = useDataLayer();

    return () => {
        sendToGTM({
            event: 'fast_category_choose',
        });
    };
};
