import { chakra } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import {
    useCalculatorContext,
    type InitialProps,
} from '../../../../modules/calculator-core/contexts/CalculatorContext';
import { CalculatorProvider } from '../../../../modules/calculator-core/components/CalculatorProvider';
import { useInitCalculatorFromExternalForm } from '../../../../modules/calculator-core/hooks/useInitCalculatorFromExternalForm';
import { BillingCopyrightTransfer } from '../../../../modules/api/hooks/useBilling';
import { FastCalculatorLayout } from '../../components/FastCalculatorLayout';

type FastInitialProps = {
    country?: string;
    residence?: string;
    legalstatus?: string;
} & InitialProps;

export const FastCalculatorComponent = () => {
    const {
        paymentDetails: { contractorCountry, contractorIsBusiness },
    } = useCalculatorContext();
    const { setValue, watch, formState } = useFormContext();
    const amount: number | null = watch('amount');
    const triggerRef = useRef<HTMLButtonElement>(null);
    const { initCalculator } = useInitCalculatorFromExternalForm();

    const submitButtonRef = useRef<HTMLButtonElement>(
        document.querySelector('#fast-price-form button[type="submit"]') as HTMLButtonElement,
    );

    useEffect(() => {
        const button = submitButtonRef.current;
        if (button === null) return;
        button.disabled = !formState.isValid;
    }, [formState.isValid]);

    useEffect(() => {
        initCalculator();

        const copyrightTransfer: HTMLInputElement | null =
            document.querySelector('#id_copyright_transfer');

        if (copyrightTransfer !== null) {
            const value = copyrightTransfer.value.toLowerCase();
            setValue(
                'copyrightTransfer',
                typeof value === 'string' && value in BillingCopyrightTransfer
                    ? BillingCopyrightTransfer[value as keyof typeof BillingCopyrightTransfer]
                    : null,
            );
        }

        setValue('contractorCountry', contractorCountry, { shouldValidate: true });
        setValue('contractorIsBusiness', contractorIsBusiness, { shouldValidate: true });

        if (amount !== null && amount >= 1) triggerRef.current?.click();
    }, [contractorCountry, contractorIsBusiness]);

    return (
        <>
            <FastCalculatorLayout />
            <chakra.button type="submit" ref={triggerRef} display="none" />
        </>
    );
};

export const FastCalculator = ({
    country,
    residence,
    legalstatus: legalStatus,
    ...props
}: FastInitialProps) => {
    const allowAutoStart = useMemo(() => {
        const input: HTMLInputElement | null = document.querySelector('#id_payment');
        return input !== null && input?.value !== '';
    }, []);

    const defaultContractorCountry = useMemo(() => {
        if (residence === undefined && country === undefined) return undefined;
        return residence !== undefined ? residence : country;
    }, [residence, country]);

    const defaultContractorIsBusiness = useMemo(() => {
        return legalStatus === undefined ? undefined : legalStatus === 'business';
    }, [legalStatus]);

    return (
        <CalculatorProvider
            {...props}
            allowAutoStart={allowAutoStart}
            defaultContractorCountry={defaultContractorCountry}
            defaultContractorIsBusiness={defaultContractorIsBusiness}
        >
            <FastCalculatorComponent />
        </CalculatorProvider>
    );
};
