import { useFormContext } from 'react-hook-form';

import { BillingCalculator } from '../../../../modules/api/hooks/useBilling';

export const useCalculatorType = () => {
    const { watch } = useFormContext();
    const billingCalculator = watch('billingCalculator');

    return {
        billingCalculator,
        isGrossToNetto: billingCalculator === BillingCalculator.G2N,
        isNettoToGross: billingCalculator === BillingCalculator.N2G,
    };
};
