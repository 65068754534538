import { Box, Stack, type BoxProps } from '@chakra-ui/react';

import { ButtonLink } from '../../../../modules/core/components/ButtonLink';
import { useDataLayer } from '../../../../modules/core/hooks/useDataLayer';
import { PaymentMethodsLogos } from '../PaymentMethodsLogos';

export type ButtonLinkAndPaymentMethodsProps = {
    id: string;
    text: string;
    href: string;
} & BoxProps;

export const ButtonLinkAndPaymentMethods = ({
    id,
    text,
    href,
    ...props
}: ButtonLinkAndPaymentMethodsProps) => {
    const { sendToGTM } = useDataLayer();

    const handleAnalytics = () => {
        sendToGTM({
            event: 'link_click',
            link_url: `https://useme.com${href}`,
            link_text: text,
            link_id: id,
        });
    };

    return (
        <Box {...props}>
            <Stack spacing={4} mt={{ base: 0, md: 4 }}>
                <ButtonLink
                    id={id}
                    w="100%"
                    colorScheme="mainPrimary"
                    href={href}
                    onClick={handleAnalytics}
                >
                    {text}
                </ButtonLink>
            </Stack>
            <PaymentMethodsLogos />
        </Box>
    );
};
