import { useTranslation } from 'react-i18next';
import {
    Box,
    HStack,
    Heading,
    Link,
    Radio,
    RadioGroup,
    Stack,
    Text,
    chakra,
    type UseRadioGroupProps,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';

import { InfoPopover } from '../../../../modules/calculator-core/components/InfoPopover';
import { BillingCopyrightTransfer } from '../../../../modules/api/hooks/useBilling';
import { useGlobalContext } from '../../../../modules/core/contexts/GlobalContext';
import { useCalculatorLanguage } from '../../../../modules/calculator-core/hooks/useCalculatorLanguage';

export type RadioCopyrightTransferFieldProps = {
    onValueChange?: UseRadioGroupProps['onChange'];
};

export const RadioCopyrightTransferField = (props: RadioCopyrightTransferFieldProps) => {
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        name: 'copyrightTransfer',
        control,
        rules: {
            required: t('common.errors.required'),
        },
    });
    const { language } = useCalculatorLanguage();
    const isPL = language === 'pl';

    return (
        <Box my={8}>
            <HStack gap={1} mb={4}>
                <Heading as="h4" size="xs" color="general.darkBlue">
                    {t('calculator_fast.copyright_transfer_field.heading')}
                </Heading>
                <CopyrightTransferInfoPopover />
            </HStack>
            <RadioGroup
                gap={{ base: '1.6rem', md: '4.8rem' }}
                display="flex"
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="flex-start"
                name={field.name}
                value={field.value}
                onChange={(value) => {
                    field.onChange(value);
                    if (typeof props?.onValueChange === 'function') {
                        props.onValueChange(value);
                    }
                }}
            >
                <Radio
                    whiteSpace={{ base: 'normal', md: 'nowrap' }}
                    size="sm"
                    alignItems="flex-start"
                    lineHeight="150%"
                    value={BillingCopyrightTransfer.license}
                    isRequired={true}
                >
                    <RadioHeaderText>
                        {t('calculator_fast.copyright_transfer.license')}
                    </RadioHeaderText>
                </Radio>
                <Radio
                    whiteSpace={{ base: 'normal', md: 'nowrap' }}
                    size="sm"
                    alignItems="flex-start"
                    lineHeight="150%"
                    value={BillingCopyrightTransfer.protocol}
                    isRequired={true}
                >
                    <RadioHeaderText>
                        {isPL
                            ? t('calculator_fast.copyright_transfer.protocol')
                            : t('calculator_fast.copyright_transfer.full_ownership_transfer')}
                    </RadioHeaderText>
                </Radio>
                <Radio
                    whiteSpace={{ base: 'normal', md: 'nowrap' }}
                    size="sm"
                    alignItems="flex-start"
                    lineHeight="150%"
                    value={BillingCopyrightTransfer.without}
                    isRequired={true}
                >
                    <RadioHeaderText>
                        {t('calculator_fast.copyright_transfer.no_copyright_transfer')}
                    </RadioHeaderText>
                </Radio>
            </RadioGroup>
            {errors.copyrightTransfer !== undefined && (
                <Text textStyle="error" color="danger.font.base" mt={2}>
                    {errors.copyrightTransfer?.message}
                </Text>
            )}
        </Box>
    );
};

const RadioHeaderText = styled(Text)`
    font-weight: normal;
    font-size: var(--ck-fontSizes-md);

    &::first-letter {
        text-transform: uppercase;
    }
`;

const CopyrightTransferInfoPopover = () => {
    const { t } = useTranslation();
    const { currentLanguage } = useGlobalContext();
    const { language } = useCalculatorLanguage();
    const isPL = language === 'pl';

    return (
        <InfoPopover header={t('calculator.copyright_transfer.popover_heading')}>
            <Stack spacing="2rem">
                <Box>
                    <PopoverHeaderText>
                        {t('calculator.copyright_transfer.license')}
                    </PopoverHeaderText>
                    {isPL
                        ? t('calculator.copyright_transfer.license.pl_description')
                        : t('calculator.copyright_transfer.license.en_description')}
                </Box>

                <Box>
                    <PopoverHeaderText>
                        {isPL
                            ? t('calculator.copyright_transfer.protocol')
                            : t('calculator.copyright_transfer.full_ownership_transfer')}
                    </PopoverHeaderText>
                    {isPL
                        ? t('calculator.copyright_transfer.protocol.pl_description')
                        : t('calculator.copyright_transfer.protocol.en_description')}
                </Box>

                <Box>
                    <PopoverHeaderText>
                        {t('calculator.copyright_transfer.no_copyright_transfer')}
                    </PopoverHeaderText>
                    {isPL
                        ? t('calculator.copyright_transfer.no_copyright_transfer.pl_description')
                        : t('calculator.copyright_transfer.no_copyright_transfer.en_description')}
                </Box>

                <Link
                    href={`https://help.useme.eu/hc/${
                        currentLanguage === 'pl' ? 'pl' : 'en-us'
                    }/articles/360014412831`}
                >
                    {t('calculator.copyright_transfer.link')}
                </Link>
            </Stack>
        </InfoPopover>
    );
};

const PopoverHeaderText = styled(chakra.div)`
    font-weight: bold;

    &::first-letter {
        text-transform: uppercase;
    }
`;
