import { type ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, chakra, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
    SelectCurrencyField,
    type SelectCurrencyFieldProps,
} from '../../../../modules/core/components/SelectCurrency';
import {
    InputPrice,
    type InputPriceProps,
} from '../../../../modules/calculator-core/components/InputPrice';
import { useCalculatorType } from '../../../../modules/calculator-core/hooks/useCalculatorType';
import { useCalculatorContext } from '../../../../modules/calculator-core/contexts/CalculatorContext';
import { newSelectCurrencyStyles } from '../../../../modules/core/components/SelectCurrency/styles';
import { FieldVatInfo } from '../FieldVatInfo';

export type PriceAndCurrencyFieldProps = {
    onChangePrice?: InputPriceProps['onChange'];
    onChangeCurrency?: SelectCurrencyFieldProps['onChange'];
} & ComponentProps<any>;

export const PriceAndCurrencyField = ({
    onChangePrice,
    onChangeCurrency,
}: PriceAndCurrencyFieldProps) => {
    const {
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation();
    const { isGrossToNetto } = useCalculatorType();
    const { getPayoutItem, getPayinItem } = useCalculatorContext();
    const { watch } = useFormContext();
    const amount = watch('amount');
    const isVat = isGrossToNetto
        ? getPayinItem('vat_amount') !== null
        : getPayoutItem('vat_amount') !== null;

    return (
        <chakra.div>
            <chakra.div position="relative">
                <FormControl
                    isInvalid={errors.amount !== undefined}
                    variant="fast"
                    h="7.8rem"
                    layerStyle="shadowedField"
                >
                    <FormLabel color="general.subheading" fontWeight="400">
                        {isGrossToNetto
                            ? `${t('calculator.invoice')} (${t(
                                  'calculator.contract',
                              ).toLowerCase()})`
                            : t('calculator.payout')}
                    </FormLabel>

                    <InputPrice
                        onChange={onChangePrice}
                        variant="unstyled"
                        size="lg"
                        fontSize={
                            (amount as boolean) && isVat && amount.toString().length > 5
                                ? '1.8rem'
                                : '2.3rem'
                        }
                        textStyle="h4"
                        paddingInlineEnd="13rem"
                        paddingInlineStart={5}
                        color="general.heading"
                        pt={4}
                    />

                    {(amount as boolean) && isVat && errors.amount === undefined && (
                        <FieldVatInfo value={amount} mt={1} />
                    )}
                </FormControl>

                <SelectCurrencyContainer>
                    <SelectCurrencyField
                        styles={newSelectCurrencyStyles}
                        onChange={onChangeCurrency}
                    />
                </SelectCurrencyContainer>
            </chakra.div>

            {errors.amount !== undefined && (
                <Text textStyle="error" color="danger.font.base" mt={2}>
                    {errors.amount?.message}
                </Text>
            )}
        </chakra.div>
    );
};

const SelectCurrencyContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: 100%;
    margin-top: var(--ck-space-1);
`;
