import {
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    type CheckboxProps,
    type IconButtonProps,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { type ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { BillingCalculator } from '../../../../modules/api/hooks/useBilling';
import { breakpoint } from '../../../../modules/design/utils/breakpoint';
import { SwitchCalculatorPopover } from '../../../../modules/calculator-core/components/SwitchCalculatorPopover';
import { ReactComponent as BillingSwitchIcon } from './billing-switch.svg';
import { useResult } from '../../hooks/useResult';
import { useCalculatorType } from '../../hooks/useCalculatorType';

export type SwitchBillingTypeProps = {
    onChange?: (
        e: ChangeEvent<HTMLInputElement>,
        billingCalculator: BillingCalculator,
        amount: string | number | null,
    ) => void;
    buttonProps?: Omit<IconButtonProps, 'aria-label'>;
} & Omit<CheckboxProps, 'onChange'>;

export const SwitchBillingType = ({
    onChange: onValueChange,
    buttonProps,
    ...props
}: SwitchBillingTypeProps) => {
    const { control, setValue, trigger, watch } = useFormContext();
    const {
        field,
        formState: { isSubmitting },
    } = useController({ name: 'billingCalculator', control });
    const currentAmount: number | null = watch('amount');
    const { result } = useResult();
    const { isGrossToNetto } = useCalculatorType();

    const ref = useRef<HTMLLabelElement | null>(null);
    const { t } = useTranslation();

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const billingCalculator =
            e.target.value === BillingCalculator.G2N
                ? BillingCalculator.N2G
                : BillingCalculator.G2N;
        void field.onChange(billingCalculator);

        let amount = result;
        if (amount === null) amount = currentAmount;

        if (isGrossToNetto) {
            const parsedResult = parseInt(amount as string, 10);
            amount = isNaN(parsedResult) ? null : parsedResult;
        }
        setValue('amount', amount);

        // Do it twice to trigger form validation
        await trigger('amount');
        await trigger('amount');

        if (typeof onValueChange === 'function') onValueChange(e, billingCalculator, amount);
    };

    return (
        <FormControl>
            <Checkbox
                {...props}
                ref={field.ref}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => {
                    void onChange(e);
                }}
                display="none"
            />
            <FormLabel display="none" ref={ref} />

            <Flex alignItems="center" justifyContent="center">
                <SwitchCalculatorPopover>
                    <SwitchBillingButton
                        aria-label={t('calculator.switch_billing_type')}
                        variant="unstyled"
                        icon={<BillingSwitchIcon />}
                        transform={{ base: 'rotate(90deg)', md: 'none' }}
                        onClick={() => ref.current?.click()}
                        isDisabled={isSubmitting}
                        cursor="pointer !important"
                        {...buttonProps}
                    />
                </SwitchCalculatorPopover>
            </Flex>
        </FormControl>
    );
};

const SwitchBillingButton = styled(IconButton)`
    position: relative;
    color: var(--ck-colors-general-darkBlue);
    border: 1px solid var(--ck-colors-general-lightBlue);
    background: var(--ck-colors-white);
    border-radius: var(--ck-radii-full);
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    min-width: auto;

    &:hover {
        background: var(--ck-colors-general-lightBlue);
    }

    &:disabled {
        cursor: default;
    }

    &:after {
        // to enlarge the clickable button area without visual changes
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6.4rem;
        height: 6.4rem;
        border-radius: 8rem;
        background: transparent;
        content: ' ';
        z-index: 0;
    }

    svg {
        width: 100%;
    }

    ${breakpoint('md')} {
        animation: make-it-wiggle 10s infinite;
        animation-delay: 6s;
    }

    @keyframes make-it-wiggle {
        0% {
            transform: rotate(0deg) scale(1);
        }
        5% {
            transform: rotate(5deg) scale(1.1);
        }
        10% {
            transform: rotate(0deg) scale(1);
        }
        15% {
            transform: rotate(-5deg) scale(1.1);
        }
        20% {
            transform: rotate(0deg) scale(1);
        }
    }
`;
