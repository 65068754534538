import { useId } from 'react';
import {
    Button,
    chakra,
    Input,
    Textarea,
    FormLabel,
    FormErrorMessage,
    FormControl,
    Box,
} from '@chakra-ui/react';
import { useFormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEmailErrorMessage } from '../../../../modules/core/hooks/useEmailErrorMessage';
import { SettleFreelancerContactPayload } from '../../../../modules/api/services/settle-freelancer-contact';
import { useReCaptcha } from '../../../../modules/core/hooks/useReCaptcha';

export type LetsTalkFormPayload = Omit<SettleFreelancerContactPayload, 'g-recaptcha-response'>;

export type LetsTalkFormProps = {
    onSubmit: (data: SettleFreelancerContactPayload) => any;
};

export function useLetsTalkForm() {
    return useForm<LetsTalkFormPayload>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
        },
    });
}

export default function LetsTalkForm(props: LetsTalkFormProps) {
    const { onSubmit } = props;
    const { t } = useTranslation();
    const form = useFormContext<LetsTalkFormPayload>();
    const errors = form.formState.errors;
    const id = useId();
    const required = t('common.errors.required');
    const email = useEmailErrorMessage();
    const { executeRecaptcha } = useReCaptcha();

    async function handleSubmit(data: LetsTalkFormPayload) {
        if (executeRecaptcha === undefined) {
            throw new Error('Recaptcha has not been loaded.');
        }
        const recaptchaToken = await executeRecaptcha('settle_freelancer_contact');
        await onSubmit({ ...data, 'g-recaptcha-response': recaptchaToken });
    }

    return (
        <chakra.form
            onSubmit={form.handleSubmit(handleSubmit) as any}
            display="flex"
            gap="6"
            flexDir="column"
        >
            <FormControl isInvalid={errors?.firstName !== undefined} variant="future">
                <FormLabel htmlFor={`${id}-firstName`}>
                    {t('settle_freelancer.lets_talk.firstName')}
                </FormLabel>
                <Input {...form.register('firstName', { required })} id={`${id}-firstName`} />
                <FormErrorMessage>{errors?.firstName?.message as any}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors?.lastName !== undefined} variant="future">
                <FormLabel htmlFor={`${id}-lastName`}>
                    {t('settle_freelancer.lets_talk.lastName')}
                </FormLabel>
                <Input {...form.register('lastName', { required })} id={`${id}-lastName`} />
                <FormErrorMessage>{errors?.lastName?.message as any}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors?.email !== undefined} variant="future">
                <FormLabel htmlFor={`${id}-email`}>
                    {t('settle_freelancer.lets_talk.email')}
                </FormLabel>
                <Input
                    {...form.register('email', { required, pattern: email })}
                    id={`${id}-email`}
                />
                <FormErrorMessage>{errors?.email?.message as any}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors?.message !== undefined} variant="future">
                <FormLabel htmlFor={`${id}-message`}>
                    {t('settle_freelancer.lets_talk.message')}
                </FormLabel>
                <Textarea {...form.register('message', { required })} id={`${id}-message`} />
                <FormErrorMessage>{errors?.message?.message as any}</FormErrorMessage>
            </FormControl>

            <Box pt="5">
                <Button
                    type="submit"
                    px="12"
                    colorScheme="success"
                    variant="solid-responsive"
                    isLoading={form.formState.isLoading}
                >
                    {t('settle_freelancer.lets_talk.send_message')}
                </Button>
            </Box>
        </chakra.form>
    );
}
