import { Text, Grid, GridItem, Divider, Box, type BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';

import { NumericFormat } from '../../../../modules/core/components/NumericFormat';
import { breakpoint } from '../../../../modules/design/utils/breakpoint';
import { useCalculatorContext } from '../../../../modules/calculator-core/contexts/CalculatorContext';
import {
    InfoPopover,
    type InfoPopoverProps,
} from '../../../../modules/calculator-core/components/InfoPopover';
import { type UseCalculatorStoreReturn } from '../../../../modules/calculator-core/hooks/useCalculatorStore';
import { useCalculatorLanguage } from '../../../../modules/calculator-core/hooks/useCalculatorLanguage';
import { usePersonalCommission } from '../../../../modules/calculator-core/hooks/usePersonalCommission';

export interface CalculationDetailsProps extends BoxProps {
    advanced?: boolean;
}

export const CalculationDetailsBase = (
    useStoreHook: CallableFunction,
    useLanguageHook: CallableFunction,
    usePersonalCommissionHook: CallableFunction,
    { children, advanced = true, ...props }: CalculationDetailsProps,
) => {
    const { t } = useTranslation();
    const { currency, getPriceComponent: getItem }: UseCalculatorStoreReturn = useStoreHook();
    const { language } = useLanguageHook();
    const { isPersonalCommission }: { isPersonalCommission: boolean } = usePersonalCommissionHook();

    return (
        <Box {...props}>
            <Divider mb={8} color="general.brightSteelGray" />

            <Text
                textStyle="body"
                color="general.subheading"
                mb={4}
                textAlign={{ base: 'center', md: 'left' }}
            >
                {t('calculator.calculation_details.title')}
            </Text>

            <GridDetails>
                {getItem('pit_advance') !== null && (
                    <>
                        <GridItemValue>
                            <NumericFormat value={getItem('pit_advance')} suffix={currency} />
                        </GridItemValue>
                        <GridItem>
                            {t('calculator.calculation_details.income_tax_caption')}
                            <InfoPopover
                                header={t('calculator.calculation_details.income_tax_caption')}
                            >
                                {t('calculator.calculation_details.income_tax_text')}
                            </InfoPopover>
                        </GridItem>
                    </>
                )}
                {getItem('commission') !== null && (
                    <>
                        {isPersonalCommission ? (
                            <GridItem colSpan={2} textAlign={{ base: 'center', md: 'left' }}>
                                <Text as="span" color="general.blue" mr="2">
                                    {t('calculator.calculation_details.commission.free_caption')}
                                </Text>
                                {t('calculator.calculation_details.commission.free_text')}
                            </GridItem>
                        ) : (
                            <>
                                <GridItemValue>
                                    <NumericFormat
                                        value={getItem('commission') ?? 0}
                                        suffix={currency}
                                    />
                                </GridItemValue>
                                <GridItem>
                                    {t(
                                        'calculator.calculation_details.commission.service_fee_caption',
                                    )}
                                    <CommissionPopover />
                                </GridItem>
                            </>
                        )}
                    </>
                )}

                {advanced &&
                    language === 'pl' &&
                    getItem('vat_amount') !== null &&
                    getItem('gross_amount') !== null && (
                        <>
                            <GridItemValue>
                                <NumericFormat
                                    value={getItem('vat_amount') ?? 0}
                                    suffix={currency}
                                />
                            </GridItemValue>
                            <GridItem>
                                VAT ({t('calculator.calculation_details.commission.vat_caption')}
                                {': '}
                                <NumericFormat
                                    value={getItem('gross_amount') ?? 0}
                                    suffix={currency}
                                />
                                )
                                <InfoPopover
                                    header={`VAT (${t(
                                        'calculator.calculation_details.commission.vat_caption',
                                    )})`}
                                >
                                    {t('calculator.calculation_details.commission.vat_text')}
                                </InfoPopover>
                            </GridItem>
                        </>
                    )}

                {children !== undefined && <GridItem colSpan={2}>{children}</GridItem>}
            </GridDetails>

            <Divider mt={8} color="general.brightSteelGray" />
        </Box>
    );
};

export const CommissionPopover = ({ variant }: { variant?: InfoPopoverProps['variant'] }) => {
    const { t } = useTranslation();
    return (
        <InfoPopover
            variant={variant}
            header={t('calculator.calculation_details.commission.service_fee_caption')}
        >
            {t('calculator.calculation_details.commission.service_fee_text')}
        </InfoPopover>
    );
};

const GridDetails = styled(Grid)`
    grid-template-columns: auto auto;
    column-gap: var(--ck-space-4);
    row-gap: var(--ck-space-5);
    color: var(--ck-colors-general-paragraph);
    line-height: 150%;
    font-size: 1.6rem;

    ${breakpoint('md')} {
        grid-template-columns: min-content auto;
        font-size: 1.4rem;
        line-height: 120%;
    }
`;

const GridItemValue = styled(GridItem)`
    text-align: right;
    font-weight: bold;
    color: var(--ck-colors-general-blue);
    font-size: 1.6rem;

    ${breakpoint('md')} {
        font-size: 1.4rem;
    }
`;

const CalculationDetailsBinded = CalculationDetailsBase.bind(
    {},
    useCalculatorContext,
    useCalculatorLanguage,
    usePersonalCommission,
);

export const CalculationDetails = (props: CalculationDetailsProps) => {
    const {
        formState: { isSubmitted },
    } = useFormContext();
    const { priceComponents } = useCalculatorContext();

    if (!isSubmitted || priceComponents?.length === 0) return <></>;

    return <CalculationDetailsBinded {...props} />;
};
