import { type ComponentProps, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    chakra,
    Button,
    Center,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    InputLeftElement,
    Link,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { useGlobalContext } from '../../../../modules/core/contexts/GlobalContext';
import { type ValidationResult, usePromoCode } from '../../../../modules/api/hooks/usePromoCode';
import { useCalculatorContext } from '../../../../modules/calculator-core/contexts/CalculatorContext';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as CloseIcon } from './x.svg';

export type InputPromoCodeProps = { discount?: number } & ComponentProps<any>;

export const InputPromoCode = ({ discount, ...props }: InputPromoCodeProps) => {
    const { t } = useTranslation();
    const { control, setValue, setError } = useFormContext();
    const triggerRef = useRef<HTMLButtonElement>(null);
    const { paymentDetails } = useCalculatorContext();
    const { currentLanguage } = useGlobalContext();
    const [displayedMessage, setDisplayedMessage] = useState('');

    const input: HTMLInputElement | null = document.querySelector('#id_promo_code');

    const {
        field: { name, value, onChange, onBlur },
        fieldState: { error },
    } = useController({
        name: 'promocode',
        control,
        defaultValue: input?.value !== undefined ? input.value : '',
    });

    const onSuccess = (response: ValidationResult) => {
        if (response.discount !== undefined) {
            setValue('discount', response.discount);
            triggerRef.current?.click();
        }
    };

    const onError = async (error: any) => {
        if (error.response.status === 429) {
            const errorMessage = `${t('calculator.promo_code.errors.too_many_requests')}`;
            setError('promocode', { type: 'manual', message: errorMessage });
        } else if (error.response.status === 403) {
            setError('promocode', {
                type: 'manual',
                message: `${t('calculator.promo_code.errors.unregistered_user')} `,
            });
        } else {
            const errorResponse: ErrorResponse | undefined = await error?.response?.json?.();
            const errorMessage = errorResponse?.errors?.code;
            setError('promocode', { type: 'manual', message: errorMessage });
        }
    };

    const { mutate } = usePromoCode({ onSuccess, onError });

    const onSubmitPromoCode = () => {
        if (input !== null) {
            input.value = value;
        }
        mutate(value);
    };

    const onRemovePromoCode = () => {
        if (input !== null) {
            input.value = '';
            onChange('');
        }
        setValue('discount', 0);
        triggerRef.current?.click();
    };

    const onEnterSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            if (paymentDetails.personalCommission === true) {
                return;
            }
            onSubmitPromoCode();
        }
    };

    const onEnterUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            (event.key === 'Enter' || event.keyCode === 13) &&
            paymentDetails.personalCommission === true
        ) {
            setError('promocode', {
                type: 'manual',
                message: `${t('calculator.promo_code.errors.personal_commission')} `,
            });
        }
    };

    const onFocus = () => {
        if (paymentDetails.isLogged === false) {
            setError('promocode', {
                type: 'manual',
                message: `${t('calculator.promo_code.errors.unregistered_user')} `,
            });
            setDisplayedMessage(`${t('calculator.promo_code.errors.unregistered_user')} `);
        } else if (paymentDetails.personalCommission === true) {
            setError('promocode', {
                type: 'manual',
                message: `${t('calculator.promo_code.errors.personal_commission')} `,
            });
        }
    };

    const onInputBlur = () => {
        onBlur();
        if (value === '') {
            onRemovePromoCode();
        }
        if (displayedMessage !== '') {
            const messageTimeout = setTimeout(() => {
                setDisplayedMessage('');
            }, 2000);

            return () => clearTimeout(messageTimeout);
        }
    };

    return (
        <chakra.div pos="relative" zIndex="0">
            <FormControl>
                <InputGroup maxW="30rem">
                    <Input
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onInputBlur}
                        onKeyDown={onEnterSubmit}
                        onKeyUp={onEnterUp}
                        onFocus={onFocus}
                        disabled={discount as boolean}
                        variant="fast"
                        size="lg"
                        pos="relative"
                        pl="6"
                        pr="10rem"
                    />
                    {(discount as boolean) && (
                        <RemoveCodeButton>
                            {String(value)}
                            <chakra.button
                                onClick={onRemovePromoCode}
                                left="calc(100% - 16px)"
                                ml="1"
                            >
                                <CloseIcon />
                            </chakra.button>
                        </RemoveCodeButton>
                    )}

                    <InputRightElement top="50%" right="3" minW="20" transform="translateY(-50%)">
                        <Button
                            onClick={onSubmitPromoCode}
                            isDisabled={
                                paymentDetails.personalCommission === true ||
                                value === '' ||
                                paymentDetails.isLogged === false
                            }
                            size="sm"
                            variant="outline-responsive"
                            w="100%"
                            p="2"
                        >
                            {t('calculator.promo_code.button')}
                        </Button>
                    </InputRightElement>
                    <SinkedFormLabel>{t('calculator.promo_code.input_label')}</SinkedFormLabel>
                </InputGroup>
                {(discount as boolean) ? (
                    <Flex>
                        <Center mt={2}>
                            <CheckIcon />
                        </Center>
                        <PromoMessage ml={1} color="var(--ck-colors-general-gray)">
                            {t('calculator.promo_code.code')} -{discount * 100}%{' '}
                            {t('calculator.promo_code.code_valid')}
                        </PromoMessage>
                    </Flex>
                ) : (paymentDetails?.isLogged === false && error?.message !== undefined) ||
                  displayedMessage !== '' ? (
                    <PromoMessage color="danger.font.base" fontWeight="bold">
                        {displayedMessage !== '' ? displayedMessage : error?.message}
                        <Link
                            color="currentcolor"
                            textDecoration="underline"
                            href={`/${currentLanguage}/users/create-account/`}
                            target="_blank"
                        >
                            {t('calculator.promo_code.errors.unregistered_user_link')}
                        </Link>
                    </PromoMessage>
                ) : (!(discount as boolean) && value !== '') ||
                  (paymentDetails.personalCommission === true && error?.message !== undefined) ? (
                    <PromoMessage color="danger.font.base" fontWeight="bold">
                        {error?.message}
                    </PromoMessage>
                ) : null}
            </FormControl>
            <chakra.button type="submit" ref={triggerRef} display="none" />
        </chakra.div>
    );
};

const SinkedFormLabel = styled(FormLabel)`
    position: absolute;
    top: 0;
    left: 1rem;
    transform: translate(0, -50%);
    padding: 0 0.5rem;
    user-select: none;
    pointer-events: none;
    max-width: calc(100% - 2rem);
    font-weight: var(--ck-fontWeights-normal) !important;
    font-size: var(--ck-fontSizes-md) !important;
    line-height: 1.9rem;
    color: var(--ck-colors-general-lightGray);

    &::before {
        content: ' ';
        background: var(--ck-colors-general-skyBlue);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 5px;
        margin: 0;
        padding: 0;
        z-index: -1;
    }
`;

const PromoMessage = styled(FormHelperText)`
    font-size: var(--ck-fontSizes-xs);
    line-height: 120%;
`;

const RemoveCodeButton = styled(InputLeftElement)`
    position: absolute;
    top: 50%;
    left: var(--ck-sizes-4);
    transform: translateY(-50%);
    width: fit-content;
    height: auto;
    padding: var(--ck-sizes-1) var(--ck-sizes-2);
    border-radius: var(--ck-sizes-1);
    font-size: var(--ck-fontSizes-xl);
    background: var(--ck-colors-general-grayishBlue);
    color: var(--ck-colors-general-heading);
`;
