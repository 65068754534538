import { type ComponentProps, useState, useEffect } from 'react';
import {
    chakra,
    Button,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocalStorage } from '@uidotdev/usehooks';

import { usePersonalCommission } from '../../../../modules/calculator-core/hooks/usePersonalCommission';

export type SwitchCalculatorPopoverProps = ComponentProps<typeof Popover> & {
    children?: React.ReactNode;
};

export function SwitchCalculatorPopover(props: SwitchCalculatorPopoverProps) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { t } = useTranslation();
    const { isPersonalCommission } = usePersonalCommission();
    const [popoverWasOpen, setPopoverWasOpen] = useLocalStorage('popoverWasOpen', false);

    useEffect(() => {
        if (!popoverWasOpen) {
            const popoverTimeout = setTimeout(() => {
                setIsPopoverOpen(true);
                setPopoverWasOpen(true);
            }, 2000);

            return () => clearTimeout(popoverTimeout);
        }
    }, [popoverWasOpen, setPopoverWasOpen]);

    return (
        <Popover
            isOpen={isPopoverOpen}
            placement="top"
            arrowSize={16}
            variant="specialHint"
            autoFocus={false}
            closeOnBlur={false}
            flip={false}
            isLazy={true}
        >
            <PopoverTrigger>{props.children}</PopoverTrigger>
            <PopoverContent>
                <PopoverHeader my="-0.5">
                    <Text fontWeight="bold" fontSize="1.3rem">
                        {t('calculator.switch_calculator_popover.caption')}
                    </Text>
                    <PopoverCloseButton onClick={() => setIsPopoverOpen(false)} />
                </PopoverHeader>

                <PopoverBody>
                    <Text fontWeight="medium" fontSize="1.3rem">
                        {isPersonalCommission ? (
                            <Trans
                                i18nKey="calculator.switch_calculator_popover.personal_commission"
                                components={{ i: <chakra.i /> }}
                            />
                        ) : (
                            <Trans
                                i18nKey="calculator.switch_calculator_popover.no_personal_commission"
                                components={{ i: <chakra.i /> }}
                            />
                        )}
                    </Text>
                </PopoverBody>

                <PopoverFooter>
                    <Button
                        onClick={() => setIsPopoverOpen(false)}
                        size="sm"
                        fontSize="1.3rem"
                        variant="link"
                        colorScheme="whiteAlpha"
                        color="white"
                        _hover={{ textDecoration: 'none' }}
                        fontWeight="normal"
                    >
                        {t('calculator.switch_calculator_popover.close')}
                    </Button>
                </PopoverFooter>
                <PopoverArrow />
            </PopoverContent>
        </Popover>
    );
}
